<template>
  <div v-if="logout" class="flex flex-col w-full items-center gap-3 mb-3">
    <h1>{{ $t("logout.logged_out_title") }}</h1>
    <p class="text-4xl text-center leading-10">
      {{ $t("logout.logged_out_text") }}
    </p>
    <ButtonPrimary
        @click="login()">{{ $t('logout.login_again') }}
    </ButtonPrimary>
  </div>
  <div v-else class="flex flex-col gap-4 items-center gap-3 mb-3">
    <h1>{{ $t("login.register_title") }}</h1>
    <div class="flex flex-col gap-1 items-center">
      <ButtonPrimary
          @click="register()"><span class="py-2 sm:px-5">{{ $t('login.register_button_title') }}</span>
      </ButtonPrimary>
        <p>{{ $t("login.login_have_account_title") }}
          <button class="font-bold text-theme-primary hover:text-theme-secondary" @click="login()">{{ $t('login.login_button_title') }}</button>
        </p>
    </div>
  </div>
</template>
<script setup lang="ts">
defineProps({
  logout: {type: Boolean, default: false},
});

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const callbackUrl = ref<string>();

function login() {
  if (callbackUrl.value === undefined || callbackUrl.value === "") {
    auth.login({redirectUri: window.location.origin});
  } else {
    auth.login({redirectUri: callbackUrl.value});
  }
}

function register() {
  if (callbackUrl.value === undefined || callbackUrl.value === "") {
    auth.register({redirectUri: window.location.origin});
  } else {
    auth.register({redirectUri: callbackUrl.value});
  }
}

function getCallbackUrl() {
  const origin = route.query['origin'];
  if (origin) {
    const callbackRoute = router.resolve("" + origin);
    callbackUrl.value = new URL(callbackRoute.href, window.location.origin).href;
  }
}

onMounted(() => {
  getCallbackUrl();
  if (auth.authenticated.value) {
    const origin = route.query['origin'];
    if (origin) {
      router.push("" + origin);
    } else {
      router.push("/");
    }
  }
});

</script>
